// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-pt-js": () => import("./../../../src/pages/404.pt.js" /* webpackChunkName: "component---src-pages-404-pt-js" */),
  "component---src-pages-tags-index-en-js": () => import("./../../../src/pages/tags/index.en.js" /* webpackChunkName: "component---src-pages-tags-index-en-js" */),
  "component---src-pages-tags-index-pt-js": () => import("./../../../src/pages/tags/index.pt.js" /* webpackChunkName: "component---src-pages-tags-index-pt-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-bike-hollidays-js": () => import("./../../../src/templates/bike-hollidays.js" /* webpackChunkName: "component---src-templates-bike-hollidays-js" */),
  "component---src-templates-bikes-page-js": () => import("./../../../src/templates/bikes-page.js" /* webpackChunkName: "component---src-templates-bikes-page-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-city-tours-js": () => import("./../../../src/templates/city-tours.js" /* webpackChunkName: "component---src-templates-city-tours-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-null-js": () => import("./../../../src/templates/null.js" /* webpackChunkName: "component---src-templates-null-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tour-calendar-js": () => import("./../../../src/templates/tour-calendar.js" /* webpackChunkName: "component---src-templates-tour-calendar-js" */),
  "component---src-templates-tour-gen-js": () => import("./../../../src/templates/tour-gen.js" /* webpackChunkName: "component---src-templates-tour-gen-js" */)
}

